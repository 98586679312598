article-list {
  --article-min-width: 300px;
  display: grid;
  grid-gap: var(--gutter1);
  grid-template-columns: repeat(auto-fill, minmax(var(--article-min-width), 1fr));
}

side-bar article-list {
  --font-colour:#6A6A6A;
  padding: 0;
  background-color: initial;
  border: none;
  grid-gap: var(--gap);
  align-content: flex-start;
}
side-bar article-list .article {
  color: #6A6A6A;
  --image-width: 120px;
  --image-width: clamp(100px, 11vw, 200px);
}
side-bar article-list .article .container {
  position: relative;
  background-color: white;
  padding: var(--gap) var(--gap) var(--gap) calc(var(--image-width) + var(--gap));
  min-height: 120px;
}
side-bar article-list .article .container .heading {
  font-size: 1.0625em;
  line-height: 1.6;
  line-height: 1.3;
  margin: 0;
}
side-bar article-list .article .container time {
  order: 1;
  margin-bottom: calc(var(--gap) * 0.5);
  color: #969695;
}
side-bar article-list .article .container .image {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  width: var(--image-width);
  height: 100%;
}