@use '_modules' as *;

article-list {
  --article-min-width : 300px;
  display:grid; 
  grid-gap:$gutter; 
  grid-template-columns: repeat(auto-fill, minmax(var(--article-min-width), 1fr));
}

side-bar article-list {

  --font-colour:#{$font-colour};
  padding:0; 
  background-color: initial;
  border:none; 
  grid-gap:$gap; 
  align-content: flex-start;

  .article {
    color:$font-colour;
    --image-width : 120px;
    --image-width : clamp(100px, 11vw, 200px);
    .container {
      position: relative;
      background-color: white;
      padding:$gap $gap $gap calc(var(--image-width) + #{$gap});
      min-height:120px; 
      .heading { 
        @include heading-h4; 
        line-height:1.3; 
        margin:0;
      }
      time { 
        order:1; 
        margin-bottom:$gap0; 
        color:$grey;
      }
      .image {
        position: absolute;
        margin:0; 
        left:0; 
        top:0; 
        width: var(--image-width);
        height:100%;
      }
    }
  }
}